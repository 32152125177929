import type { IBrowserData } from '@/services/models/Order';
import { mediaMatches } from '../components/core-ui/Breakpoints';

/**
 * A util for getting browser data collected during the place order process.
 * @returns The browser data object.
 */
export function getBrowserData(): IBrowserData {
  const { userAgent } = window.navigator;
  const isDesktop = mediaMatches(window, 'desktop');

  return { userAgent, shopperDevice: isDesktop ? 'desktop' : 'mobile' };
}
